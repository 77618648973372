import BaseComponent from './BaseComponent'

class Alert extends BaseComponent {
  constructor(componentName){
    super(componentName)

    this.options = {
      title: '',
      text:'good job',
      duration: 3000,
      wrapper: 'list--alerts', // html id value
      stackDir:'bottom',       // 'top', 'bottom'
      position:'right',        // 'left', 'center', 'right'
      offset: 15,
      aria: {
        liveRegion:'assertive',
      },
      className:'',
      actions: null
    }
  }

  init() {}

  build(customOptions) {
    const options = {...this.options, ...customOptions} // Merging
    const { title, text, position, aria, className, stackDir, type, id, actions } = options

    if(position !== 'left' && position !== 'center' && position !== 'right') {
      throw `The position '${position}' isn't valid`
    }
    if(stackDir !== 'top' && stackDir !== 'bottom') throw `The stackDir '${stackDir}' isn't valid`;

    let backgroundColor = this.getBackgroundColor(type)

    const li = `
      <li class='cmp-alert alert ${position} ${className}' data-stack='${stackDir}' aria-live='${aria.liveRegion}' style='background-color: ${backgroundColor}' data-last='true' data-id='${id}'>
        ${this.getContent(title, text)}
        ${this.getActions(actions)}
      </li>
    `

    this.display(options, app.parser.parseToHTML(li))
  }

  getBackgroundColor(type) {
    switch(type) {
      case 'success':
        return '#414EED'
      break
      case 'notice':
        return '#414EED'
      break
      case 'error':
        return '#ED7A41'
      break
      case 'warning':
        return '#EDA241'
      break
      case 'info':
      default:
        return '#5A657D'
      break
    }
  }

  display(options, alert) {
    const { duration, offset } = options
    const wrapper = !options.wrapper ? document.body : document.getElementById(options.wrapper)
    if(!wrapper)  throw 'Root element is not defined'

    // Adding the DOM element
    wrapper.insertBefore(alert, wrapper.firstChild);

    this.reposition(offset)
    this.initTimer(duration, alert, offset)
  }

  initTimer(duration, alert, offset) {
    if(duration > 0) {
      // alert.timeOutValue = setTimeout(() => {
      setTimeout(() => {
        this.remove(alert, offset)
      }, duration)
    }
  }

  remove(alert, offset) {
    alert.classList.remove('is-visible')
    alert.setAttribute('aria-live', 'off')

    setTimeout(() => {
      alert.parentElement.removeChild(alert)
      this.reposition(offset)
    }, 400)
  }

  reposition(offset = 15) {
    const alerts = document.getElementsByClassName('cmp-alert')
    const stack = { top: offset, bottom: offset }

    for(let alert of alerts) {
      const stackDir = alert.dataset.stack
      const height = alert.offsetHeight

      alert.style[stackDir] = `${stack[stackDir]}px`
      stack[stackDir] += height + offset

      if(alert.dataset.last == 'true') {
        alert.classList.add('is-visible')
        alert.dataset.last = false
      }
    }
  }

  getContent(title, text) {
    return `
      <div>
        ${title ? `<strong>${title}</strong>` : ''}
        ${text  ? `<span>${text}</span>` : ''}
      </div>
    `
  }

  getActions(actions) {
    return `${actions ? `
      <ul class='actions'>
        ${
          actions.map(item => `
            <li>
              ${item.href ?
                `<a href='${item.href}' ${item.ajax ? `class='cmp-ajax-trigger'` : ''} ${item.confirm ? `data-confirm='${item.confirm}'` : ''} data-method='${item.method}'>${item.text}</a>`
              :
                `<button type='button' class='cmp-modal-trigger' data-modal-target='${item.target}'>${item.text}</button>`
              }
            </li>
          `).join('')
        }
      </ul>` : ''
    }`
  }

  check(alert) {
    const wrapper = document.getElementById(this.options.wrapper);
    const alertExist   = wrapper.querySelector(`li[data-id='${alert.id}']`);
    if(alert.id && alertExist) {
      this.update(alert)
    } else {
      this.build(alert)
    }
  }

  update({ id, title, text, actions, type }) {
    const wrapper = document.getElementById(this.options.wrapper);
    const alert = wrapper.querySelector(`li[data-id='${id}']`);

    if(alert) {
      const actionsWrapper = alert.getElementsByClassName('actions')[0]
      const div = this.getContent(title, text)

      // Remove current text
      alert.removeChild(alert.firstElementChild);
      // Append new text
      if(actionsWrapper) {
        alert.insertBefore(app.parser.parseToHTML(div), actionsWrapper)
      } else {
        alert.appendChild(app.parser.parseToHTML(div))
      }

      if(actions) {
        const newActions = this.getActions(actions);

        // Remove current actions
        alert.removeChild(alert.lastElementChild);

        // Update new ones
        alert.appendChild(app.parser.parseToHTML(newActions))
      }

      if(type) alert.style.backgroundColor = this.getBackgroundColor(type)

    }

  }
}

export default Alert


// displayAlert({
//   id: 'alert-unique-id',
//   title: 'Last edited',
//   text: `<span class='datetime'>27/03/2020, 11:48</span> by <span class='author'>lluis@you.digital</span>`,
//   type: 'warning',
//   duration: 0,
//   actions: [
//     {
//       href: '?content_id=92/discardchanges',
//       method: 'POST',
//       text: 'Discard',
//			 ajax: true,  // method key only needed if ajax true
//     },
//     {
//       target: 'some-modal-target-id',
//       text: 'Edit',
//     }
//   ]
// })
