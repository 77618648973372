import BaseComponent from './BaseComponent'

class FilterGrid extends BaseComponent {
  constructor(componentName){
    super(componentName)
  }

  init(component) {
		const element = component.element
		const form = element.form

		if(element.name === 'btn--select-all') {
			element.addEventListener('click', (e) => {
				const filterCheckboxes = form.getElementsByClassName('filter-checkbox')
				const allAlreadySelected = element.dataset.allSelected === 'true'
				for (let checkbox of filterCheckboxes) {
					checkbox.checked = !allAlreadySelected
				}

				element.dataset.allSelected = !allAlreadySelected
				const span = element.querySelector('span')
				span.textContent = allAlreadySelected ? 'Select All' : 'Deselect All'
				this.allSelected(filterCheckboxes[0], !allAlreadySelected, filterCheckboxes.length)
				this.displayActionButtons(filterCheckboxes[0], !allAlreadySelected, filterCheckboxes.length)
			})
		} else if(element.name === 'btn--restore-selected') {
			element.addEventListener('click', (e) => {
				app.components.alert.build({
					title: `Restoring selected items`,
					text: 'This may take a while, please wait...'
				})
			})
		} else if(element.name === 'btn--delete-selected') {
			element.addEventListener('click', (e) => {
				app.components.alert.build({
					title: `Deleting selected items`,
					text: 'This may take a while, please wait...'
				})
			})
		}
  }

  allSelected(element, allCheckboxesSelected) {
		const btnSelectAll = element.form.querySelector('button[name="btn--select-all"]')[0]

		if(btnSelectAll) {
			btnSelectAll.dataset.allSelected = allCheckboxesSelected
		}
  }

	displayActionButtons(element, someItemsSelected, amount) {
		// console.log('displayActionButtons element', element)
		// console.log('displayActionButtons someItemsSelected', someItemsSelected)
		const form = element.form
		if(!form) return console.error('No element provided to FilterGrid:', element)

		const btnRestoreSelected = form.querySelector('button[name="btn--restore-selected"]')
		if(btnRestoreSelected) {
			btnRestoreSelected.disabled = !someItemsSelected
			const span = btnRestoreSelected.querySelector('span')
			span.textContent = `Restore selected items (${amount})`
		}

		const btnDeleteSelected = form.querySelector('button[name="btn--delete-selected"]')
		if(btnDeleteSelected) {
			btnDeleteSelected.disabled = !someItemsSelected
			const span = btnDeleteSelected.querySelector('span')
			span.textContent = `Delete selected items (${amount})`
		}
  }
}
export default FilterGrid
