import BaseComponent from './BaseComponent'

class FilterInputCheckbox extends BaseComponent {
  constructor(componentName){
    super(componentName)
  }

  init(component) {
    // this.updateFilterGrid(component)
		const element = component.element
		element.addEventListener('click', (e) => {
			console.log(e.target.checked)

			const form = element.form
			console.log('form', form)

			const filterCheckboxes = form.getElementsByClassName('filter-checkbox')
			let checkedCheckboxes = []
			for (let checkbox of filterCheckboxes) {
				if(checkbox.checked) checkedCheckboxes.push(checkbox.value)
			}

			app.components.filterGrid.allSelected(element, checkedCheckboxes.length === filterCheckboxes.length)
			app.components.filterGrid.displayActionButtons(element, checkedCheckboxes.length > 0, checkedCheckboxes.length)
		})
  }
}
export default FilterInputCheckbox
